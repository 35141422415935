import React from 'react'
import { graphql, Link } from "gatsby"
import Layout from '../../layouts/index'
import Seo from '../seo'

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return <Text>{children}</Text>
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
            return (
                <img style={{maxHeight:"600px"}} src={node.data.target.fields.file['en-US'].url} />
            );
        }
    },

}

const Article = ({ data, pageContext }) => {

    const article = data.contentfulNewsItem;
    const date = new Date(article.updatedAt);

    return (
        <Layout>
            <Seo
                title={article.articleTitle + ' | Dafinity'}
                description={article.articleTitle + ' | Dafintiy'}
            />
            <section className="common-wrapper mt-10 px-15 px-4-md mb-20">
                <header>
                    <Link to="/actualites/" className="button f6">{'< Retour'}</Link>
                    <h1 className="f1 font-bold">{article.articleTitle}</h1>
                    <span className="f5 text-grey-6 font-normal">Publié le : <strong className="font-bold">{date.toLocaleString('fr-FR', { timeZone: 'UTC' })}</strong></span>
                    <div className="bc-blue bs-solid bt-w-0 bl-w-0 br-w-0 bb-w-3 relative py-5 mb-3"></div>
                </header>
                <div className="mt-10">
                    {article.body1 && article.body1.json && <div className="news-item">{documentToReactComponents(article.body1.json, options)}</div>}
                </div>
            </section>
        </Layout>
    )
}

export default Article;

export const pageQuery = graphql`
  query FetchArticle($slug: String!) {
    contentfulNewsItem(slug:{ eq: $slug }) {
            slug
            articleTitle
            updatedAt
            body1 {
              json
            }
    }
  }
`